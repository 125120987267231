var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.Sentence)?_c('div',[(!_vm.boundedTextContainer)?_c('p',{attrs:{"id":`order-hint-${_vm.unique_id_sentence}`}},[(_vm.user && !_vm.user.is_student && _vm.showCognitiveLevel)?[(_vm.cognitiveLeveLColor.length > 0)?[_c('span',{class:{ verb: _vm.cognitiveLevel },style:({
            'background-color': _vm.cognitiveLeveLColor + ' !important',
            color: _vm.$lightOrDark(_vm.cognitiveLeveLColor)
              ? '#2b2a2a !important'
              : 'white !important',
          }),attrs:{"id":`sentence-action-${_vm.unique_id_sentence}`}},[_c('u',[_vm._v(_vm._s(String(_vm.Sentence.action).trim()))])])]:[_c('span',{class:{ verb: _vm.cognitiveLevel },attrs:{"id":`sentence-action-${_vm.unique_id_sentence}`}},[_c('u',[_vm._v(_vm._s(String(_vm.Sentence.action).trim()))])])],_vm._v(" "+_vm._s(_vm.Sentence.full_sentence ? String(_vm.Sentence.full_sentence) .substr(String(_vm.Sentence.full_sentence).indexOf(" ") + 1) .trim() == String(_vm.Sentence.full_sentence.trim().split(" ").shift()) ? "" : String(_vm.Sentence.full_sentence).substr( String(_vm.Sentence.full_sentence).indexOf(" ") + 1 ) : "")+" ")]:[_c('span',[_vm._v(_vm._s(_vm.Sentence.full_sentence))])]],2):_c('p',{attrs:{"id":`order-hint-${_vm.unique_id_sentence}`}},[(_vm.user && !_vm.user.is_student && _vm.showCognitiveLevel)?[(!_vm.single_line)?[_c('span',{class:{ verb: _vm.cognitiveLevel },attrs:{"id":`sentence-action-${_vm.unique_id_sentence}`}},[_c('u',[_vm._v(_vm._s(_vm.Sentence.action)+" ")])]),_c('BoundedTextContainer',{attrs:{"Text":_vm.fullSentenceText,"Large":_vm.Large,"tooltip_text":_vm.tooltipText}})]:[_c('div',{staticClass:"single-line",on:{"click":(e) => e.target.classList.toggle('single-line')}},[_c('span',{class:{ verb: _vm.cognitiveLevel },attrs:{"id":`sentence-action-${_vm.unique_id_sentence}`}},[_c('u',[_vm._v(_vm._s(_vm.Sentence.action)+" ")])]),_vm._v(" "+_vm._s(_vm.fullSentenceText)+" ")])]]:[_c('BoundedTextContainer',{attrs:{"Text":_vm.Sentence.full_sentence,"Large":_vm.Large,"tooltip_text":_vm.tooltipText,"single_line":_vm.single_line}})]],2),(_vm.cognitiveLevel && _vm.user && !_vm.user.is_student && _vm.showCognitiveLevel)?_c('b-tooltip',{staticClass:"hint",attrs:{"variant":"secondary","delay":"300","target":`sentence-action-${_vm.unique_id_sentence}`}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo"))+" "+_vm._s(_vm.cognitiveLevel.level)+" ")]):(
      _vm.showMultipleCognitiveLevel.length > 0 &&
      _vm.user &&
      !_vm.user.is_student &&
      _vm.showCognitiveLevel
    )?_c('b-popover',{staticClass:"hint",attrs:{"variant":"secondary","placement":"top","triggers":"hover","target":`sentence-action-${_vm.unique_id_sentence}`},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Este verbo se encontró en:")]},proxy:true}],null,false,3753465773)},_vm._l((_vm.showMultipleCognitiveLevel),function(cognitive_level,index){return _c('div',{key:'multiple-cognitive-level-' + _vm.unique_id_sentence + '-' + index},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.cognitivelevel", false, "Nivel Cognitivo"))+" "+_vm._s(cognitive_level.level)+" ")])}),0):(_vm.user && !_vm.user.is_student && _vm.showCognitiveLevel)?_c('b-tooltip',{staticClass:"hint",attrs:{"variant":"secondary","delay":"300","target":`sentence-action-${_vm.unique_id_sentence}`,"placement":"top"}},[_vm._v(" Verbo sin nivel cognitivo. ")]):_vm._e(),(_vm.order_hint)?_c('b-tooltip',{attrs:{"variant":"danger","delay":"300","target":`order-hint-${_vm.unique_id_sentence}`,"placement":"bottom"}},[_vm._v(" Este "+_vm._s(_vm.$getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso"))+" ya cuenta con "+_vm._s(_vm.$getVisibleNames("mesh.competence", false, "Competencia"))+" en ese orden. ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }